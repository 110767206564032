export default defineNuxtRouteMiddleware(async (to) => {
  const { $api } = useNuxtApp()
  const { setRouteParams } = useSetRouteParams()

  try {
    let query = '?include=house&fields[house]=slug'
    if (to?.query['private-sale-token']) {
      query = `${query}&${to?.query['private-sale-token']}`
    }

    const houseSale = await $api.v3.houseSaleInformation.read(
      to.params.slug,
      query,
    )

    setRouteParams({
      slugEn: houseSale?.included?.houses?.slug?.en || '',
      slugFr: houseSale?.included?.houses?.slug?.fr || '',
    })
  } catch {
    const localePath = useLocalePath()
    const redirectLink = localePath({ name: 'realEstateListings' })

    return navigateTo(redirectLink, { redirectCode: 301 })
  }
})
